import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { State } from "state";
import localforage from "localforage";

/**
 * seful when you’re receiving bank one by one, for example, in real-time updates.
 * @param state current state
 * @param bank to add to the state
 * @returns the new state with the new added wallet
 */


export const addBank = async (state: State, bank: IGenericAccount): Promise<State> => {
  // Si un compte avec le même ID ou IBAN existe déjà
  if (state.wallets.some(existingBank => existingBank.id === bank.id || existingBank.identification === bank.identification)) {
    const updatedWallets = state.wallets.map(existingBank => {
      if (existingBank.id === bank.id || existingBank.identification === bank.identification) {
        // On retourne toujours la nouvelle version (bank) car c'est la plus récente
        return bank;
      }
      return existingBank;
    });

    await localforage.setItem('State', { ...state, wallets: updatedWallets });
    return { ...state, wallets: updatedWallets };
  }

  // Si c'est un nouveau compte, on l'ajoute simplement
  const newState = { ...state, wallets: [...state.wallets, bank] };
  await localforage.setItem('State', newState);
  return newState;
};



export const deleteBanks = (state: State, accountsId: string[]) => {
  const newState: State = { ...state };
  newState.wallets = newState.wallets.filter((wallet) => !accountsId.includes(wallet.id));
  return newState;
};


/**
 * useful when you’re receiving a batch of banks at once, for example,
 * when initializing the app or fetching updates.
 * @param state current state
 * @param banks to add to the state
 * @returns the new state with the new added wallets
 */
export const addBanks = (state: State, banks: IGenericAccount[]): State => {
  return { ...state, wallets: [...state.wallets, ...banks] };
};

export const addCountry = (state: State, country: string): State => {
  return { ...state, selectCountryBank: country };
};

export const addInstitution = (
  state: State,
  bank: YapilyBankDetailsModel
): State => {
  return { ...state, selectedBankToAdd: bank };
};

export const addTransaction = (state: State, transaction): State => {
  const newState: State = { ...state };
  let matchingIndex;
  for (let i = 0; i < newState.wallets.length; i++) {
    if (newState.wallets[i].identification === transaction.senderIdentification || transaction.payerDetails.accountIdentifications[0].identification) {
      matchingIndex = i
    }
    newState.wallets[matchingIndex].transactions = [...state.wallets[matchingIndex].transactions, transaction]
  }
  return newState
}
